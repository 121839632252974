body {
  font-family: 'Titillium Web', sans-serif;
}

.hisports-root {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.iframed .container {
  max-width: 100%;
}

main .container {
  min-height: calc(100vh - 88px - 88px);
  background-color: white;
}

.font-weight-extralight {
  font-weight: 200;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-semibold {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.text-grey {
  color: #9B9B9B;
}

.text-large {
  font-size: 1.25rem;
}

.page-link {
  color: #000;
}

.page-link:focus,
.page-link:hover {
  color: #000;
}

.page-item.active .page-link {
  background-color: #185732;
  border-color: #185732;
  z-index: 0;
}

.no-top-gutter {
  padding-top: 3em;
}

.table-responsive {
  margin-bottom: 1rem;
}

.table-responsive .table {
  margin-bottom: 0;
}

.table-responsive::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.05);
  height: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
}

.pagination {
  justify-content: center;
}

.team-logo {
  background: center no-repeat;
  background-size: contain;
  height: 50px;
  width: 50px;
}

.team-name {
  min-width: 125px;
}

.team-header .team-logo {
  width: 200px;
  height: 150px;
}

.schedule-link {
  display: block;
}
