.cover {
  position: relative;
  height: 400px;
  max-height: 40vh;
  overflow: hidden;
}

.cover-image {
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: 0 0;
  min-width: 100vw;
  height: 100%;
}

@media (max-width: 900px) {
  .cover .image-filter {
    background: rgba(0, 0, 0, 0.5);
  }
}

.cover-content {
  @extend .container;

  position: relative;

  padding-top: 12px;
  padding-bottom: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 100%;
  min-height: 100% !important;
  background-color: inherit !important;
  color: white;
  text-shadow: 0 0 5px #000;
  z-index: 10;
}
