.profile-picture-placeholder {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid $gray-400;
}

.row-player.has-profile.profile-open,
.row-profile.profile-open,
.row-player.has-profile:hover {
  background-color: $gray-100;
}

.row-player.has-profile {
  cursor: pointer;
}

.row-profile .no-gutters {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}