.filter {
  min-width: 175px;
}

.filter.filter-officeId,
.filter.filter-scheduleId {
  min-width: 300px;
  flex: 1;
}

.team-filters {
  .filter {
    min-width: 300px;
    width: 75%;
  }
}

@media (min-width: 991px) {
  .main-filters,
  .league-filters {
    display: flex;
  }

  .filter {
    margin-right: 8px;
  }

  .filter:last-child {
    margin-right: 0;
  }
  
  .filter.filter-seasonId {
    width: 120px;
    min-width: 120px;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .filter {
    min-width: 150px;
  }

  .filter.filter-officeId,
  .filter.filter-scheduleId {
    min-width: 300px;
  }
}

@media (max-width: 991px) {
  // .filter {
  //   width: 50%;
  //   line-height: 3;
  // }

  .filter {
    width: 100%;
    min-width: 0;
    margin-bottom: 5px;
  }
}