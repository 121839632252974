nav {
  min-height: 5.5rem;

  .nav-item {
    font-weight: 300;
    height: 5.5rem;

    @extend .d-flex;
    @extend .flex-row;
    @extend .justify-content-center;
    @extend .align-items-center;

    .dropdown-item {
      padding: 0;
      a {
        display: block;
      }
    }
  }

  .nav-link {
    @extend .pt-3;

    text-transform: uppercase;
    font-size: 0.9rem;
    color: $brand !important;
    padding: 1rem;

  }

  li.nav-item.active,
  li.nav-item:hover,
  div.nav-item:hover {
    @extend .bg-brand;

    > a {
      @extend .text-light;
    }
  }
}

header {
  .navbar-brand img {
    height: 63px;
  }
}

.dropdown-item:active {
  background: none !important;
  color: black !important;
}

.schedule .page-header,
.stats .page-header,
.standings .page-header,
.team .page-header {
  padding-left: 1rem;
}

.page-header h1 {
  font-size: 2.625rem;
  text-transform: uppercase;
}

.page-header img {
  padding-right: 1rem;
}

nav li.menu-item.active.current_page_item,
nav li.menu-item.active.current-menu-item {
  background-color: transparent !important;
}

nav li.menu-item.active.current_page_item > a,
nav li.menu-item.active.current-menu-item > a {
  color: $brand !important;
  font-weight: 700;
}

.navbar-expand-sm .navbar-nav .nav-link {
  padding: 1rem;
}

@media(max-width: 575px) {
  .navbar-nav .dropdown-menu {
    position: absolute;
    width: 100%;
  }
}