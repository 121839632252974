.month {
  text-align: center;
  font-weight: 500;
}

.week {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.week-date {
  height: 60px;
  width: 60px;
  padding: 3px;
  margin: auto 6px;
  text-align: center;
  border-radius: 4px;
  cursor: default;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover:not(.current) {
    background-color: $gray-100;
  }
  &.current {
    // font-weight: bold;
    border: 1px solid $gray-400;
  }
  
  .day {
    font-weight: 100;
    font-size: 90%;
  }
}