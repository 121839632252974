.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Content--after-open {
  transform: translateX(0)!important;
}

.ReactModal__Content--before-close {
  transform: translateX(100%)!important;
}

main .boxscore .row.no-gutters .border {
  border-width: 1px!important;
}

@media (min-width: 575px) {
  .boxscore-modal {
    min-width: 575px;
  }
}

@media (max-width: 575px) {
  .boxscore-modal {
    min-width: 100vw;
  }
}
