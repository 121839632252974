main .container div.row.no-gutters {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (max-width: 575px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.img-wrapper {
  overflow: hidden;
  position: relative;
  height: 350px;

  img {
    @extend .w-100;

    height: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.image-filter {
  position: absolute;
  top: 0;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, rgba(0, 0, 0, 0.4) 61%, rgba(0, 0, 0, 0.75) 100%);
}

.rte-content {
  white-space: pre-line;
  
  td {
    vertical-align: middle;
  }
}

.entry-content {
  font-size: 1.15rem;
  letter-spacing: 0.014rem;
  word-spacing: 0.2rem;
  line-height: 2rem;
  font-weight: 300;

  p {
    margin-bottom: 2.5rem;
  }
}
