.gamelist-row {
  &.approved:hover {
    background-color: $gray-100 !important;
    cursor: default;
  }
  td {
    vertical-align: middle;
  }
  .game-details {
    width: 275px;
    max-width: 275px;
    padding-right: 10px;
    border-right: 1px solid $gray-200;
    margin: auto 15px auto 0;
    overflow: hidden;

    @media (max-width: 575px) {
      width: 175px;
      max-width: 175px;
    }
  }
  .game-meta {
    font-size: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .game-matchup {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    align-content: center;

    @media (max-width: 575px) {
      display: block;
    }

    .game-result {
      margin: auto 0;
      text-align: center;

      @media (max-width: 575px) {
        margin: 10px auto;
      }

      &.vs {
        color: $gray-600;
      }
      &.score {
        display: flex;
        justify-content: center;
        font-size: 1.25em;
      }
      .result {
        margin: 0 8px;
      }
      .result-win {
        font-weight: bold;
      }
    }
  }

  .game-time {
    font-weight: bold;
  }
  .game-status {
    display: inline-block;
    font-size: 90%;
    background-color: rgba($yellow, .15);
    border: 1px solid rgba($yellow, .25);
    border-radius: 5px;
    padding: 0 5px;
    margin-left: 10px;
  }

  .game-comments {
    display: inline-block;
    font-size: 85%;
    background-color: rgba($yellow, .15);
    border-left: 3px solid rgba($yellow, .5);
    padding: 3px 5px;
    font-style: italic;
    white-space: pre-line;

    &.minimized {
      cursor: zoom-in;
    }
  }
  .game-periods {
    color: $gray-500;
    font-size: 80%;
    font-weight: normal !important;
  }

  .game-surface {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .kraft {
      max-width: 32px;
      height: 32px;
      margin-left: 8px;
    }
  }
}
