.nav-selector-menu {
  max-height: 550px;
  overflow-y: auto;

  &.show {
    width: 75vw;
    max-width: 1000px;
    margin-left: -0.2rem;
    min-height: 22rem;
    margin-top: -1px;
    padding: 0.75rem 0;
    border-radius: 0 !important;
  }
}

.nav-selector-step > section {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}

.nav-selector-option {
  width: 9.3rem;
  text-align: center;
  height: 8.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    padding-top: 1rem;
    font-size: 0.85rem;
  }

  h5 {
    font-size: 0.7rem;
  }

  h4,
  h5 {
    margin: 0;
  }

  > a {
    width: 100%;
    height: 100%;
    white-space: normal;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-decoration: none;
  }
}

.nav-selector-menu span {
  font-weight: 600;
  white-space: nowrap;
}

.nav-selector-step h3 {
  font-weight: 600;
}

.nav-selector-step span {
  margin: 0 auto;
}

.nav-selector-selected img {
  height: 50px;
}

.nav-selector-selected span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.nav-selector-logo {
  height: 40px;
  width: 100%;
  background: center no-repeat;
  background-size: contain;
}

.nav-selector-team-nav-logo {
  height: 50px!important;
}

@media (max-width: 575px) {
  .nav-selector {
    width: 100%;
  }

  .nav-selector-menu {
    width: 100% !important;
  }

  .nav-selector-menu span {
    white-space: normal;
  }

  .nav-selector-step section {
    justify-content: space-between!important;
  }
}

@media (min-width: 576px) {
  .nav-selector-container {
    display: inline-flex;
  }
}
