@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,600,700');

body {
  font-family: 'Titillium Web', sans-serif;
}

h1,
h2 {
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1rem;
}

b,
strong {
  font-weight: bold;
}

.single-ajde_events .evo_page_content {
  @extend .col-12;
}

a {
  color: $brand;
}
