.card {
  border-radius: 0 !important;

  &.shadowed {
    text-shadow: 0 0 5px #000;
  }
}

.card h2 {
  font-size: 1.5rem;
  font-weight: 600;

  max-height: 100%;
  overflow: hidden;
  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.card-img {
  overflow: hidden;
  filter: brightness(60%) grayscale(50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card a {
  text-decoration: none;
}

.card-news {
  height: 300px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;

  .card-img {
    height: 100%;
  }
}