.player-profile-row {
  display: flex;
  flex-direction: row;
}

.player-main-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 12px;
  border-right: 1px solid #E0E0E0;
}

.stat-right-border {
  border-right: 1px solid #E0E0E0;
  padding-inline: 12px;
}

.player-secondary-row {
  display: flex;
  padding: 12px;
  height: 350px;
}

.player-secondary-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #E0E0E0;
}

.player-attributes {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
}

.player-current-stats {
  padding-left: 24px;
  align-self: end;
}

.team-logo-container {
  height: 200px;
  width: 200px;
  object-fit: contain;
  padding-bottom: 12px;
}

.profile-picture {
  margin: 24px;
  width: 200px;
  height: 200px;
}

.profile-stats {
  display: flex;
  gap: 10px;
}

.team-logo {
  width: 100px;
}

.player-attribute {
  display: flex;
  flex-direction: row;
}
