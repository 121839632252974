@import "common/variables";

@import "bootstrap/scss/bootstrap";

@import "common/global";
@import "components/cards";
@import "components/cover";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/pages";

@import 'widget';
@import 'team-selector';
