.sorting-container {
  text-align: -webkit-center;
}

.sorting-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: flex;
  align-items: center;
}

.sorting-button:hover {
  opacity: 0.5;
}

.sorting-button:focus {
  outline: none;
}
