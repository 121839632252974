footer {
  height: 5.5rem;
}

footer.hisports img {
  width: 100px;
  height: 31px;
}

img.img-fluid.sponsor {
  max-width: 10em;
}

.footer-social {
  flex-grow: 2;
  text-align: right;

  a:hover svg {
    fill: white;
  }
}